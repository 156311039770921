import { createTheme, lighten } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#22305D",
    },
    secondary: {
      main: "#4476b7",
    },
  },
  typography: {
    fontFamily: "Lato",
  },
});

export default theme;