import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import theme from "./theme";


function Homepage() {
  return (
    <Box maxWidth="100vw">
      <Container
        sx={{ overtflow: 'scroll', marginTop: '30px', marginBottom: '30px' }}
      >
        <Stack
          direction="row"
          spacing="16px"
          justifyContent="space-between"
          alignItems="center"
          sx={{ position: 'relative' }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/logo.svg`}
            fit="contain"
            width="200px"
          />
          <Stack
            direction="row"
            spacing="35px"
            alignItems="center"
          >
            <Button
              color="primary"
              size="medium"
              variant="text"
              sx={{
                borderRadius: 28,
                padding: '20px',
                height: '32px',
                border: '1px solid white',
                ':hover': { border: '1px solid #22305D' }
              }}
              onClick={() => window.location.href = 'https://services.psdi.ac.uk/'}
            >
              <Typography variant="subtitle1">Services</Typography>
            </Button>
            <Skeleton
              variant="rounded"
              width="100px"
              height="32px"
            />
            <Skeleton
              variant="rounded"
              width="100px"
              height="32px"
            />
            <Skeleton
              variant="rounded"
              width="100px"
              height="32px"
              onClick={() => window.location.href = `${process.env.PUBLIC_URL}/arch.png`}
            />
          </Stack>
          <Box
            position="absolute"
            right="450px"
            top="70px"
          >
            <img
              src={`${process.env.PUBLIC_URL}/click_me.png`}
              fit="contain"
              width="200px"
            />
          </Box>
        </Stack>
        <Box
          sx={{ height: '300px' }}
          marginTop="40px"
          position="relative"
        >
          <Skeleton
            variant="text"
            width="100%"
            height="400px"
            sx={{ transform: 'scale(1, 1) !important' }}
            animation="false"
          />
          <Box
            width="100%"
            position="absolute"
            top="0"
          >
            <Stack
              direction="row"
              spacing="16px"
              justifyContent="space-between"
              paddingRight="150px"
              paddingLeft="80px"
            >
              <Box
                paddingTop="50px"
                paddingBottom="50px"
              >
                <Skeleton
                  variant="text"
                  width="300px"
                  height="300px"
                  sx={{ transform: 'scale(1, 1) !important' }}
                />
              </Box>
              <Stack
                direction="column"
                spacing="16px"
                justifyContent="center"
              >
                <Typography
                  variant="h2"
                  color="white"
                  fontWeight="bold"
                  paddingBottom="15px"
                >
                  Dummy Frontpage
                </Typography>
                <Skeleton
                  variant="text"
                  width="250px"
                  height="20px"
                />
                <Skeleton
                  variant="text"
                  width="250px"
                  height="20px"
                />
                <Skeleton
                  variant="text"
                  width="200px"
                  height="20px"
                />
              </Stack>
            </Stack>
          </Box>
        </Box>
        <Box
          width="100%"
          height="100px"
        />
        <Box
          width="100%"
          marginTop="40px"
          justifyContent="space-around"
          flexDirection="row"
          display="flex"
        >
          <Skeleton
            variant="text"
            width="200px"
            height="100px"
            sx={{ transform: 'scale(1, 1) !important' }}
          />
          <Skeleton
            variant="text"
            width="200px"
            height="100px"
            sx={{ transform: 'scale(1, 1) !important' }}
          />
          <Skeleton
            variant="text"
            width="200px"
            height="100px"
            sx={{ transform: 'scale(1, 1) !important' }}
          />
          <Skeleton
            variant="text"
            width="200px"
            height="100px"
            sx={{ transform: 'scale(1, 1) !important' }}
          />
        </Box>
        <Box
          width="100%"
          marginTop="40px"
        >
          <Skeleton
            variant="text"
            width="100%"
            height="150px"
            sx={{ transform: 'scale(1, 1) !important' }}
          />
        </Box>
        <Grid
          container={true}
          spacing={2}
          paddingTop="40px"
        >
          <Grid
            item={true}
            xs={12}
            sm={6}
          >
            <Stack
              direction="column"
              spacing="16px"
              alignItems="center"
            >
              <Skeleton
                variant="circular"
                width="100px"
                height="100px"
                sx={{ transform: 'scale(1, 1) !important' }}
              />
              <Skeleton
                variant="text"
                width="300px"
                height="20px"
                sx={{ transform: 'scale(1, 1) !important' }}
              />
              <Skeleton
                variant="text"
                width="300px"
                height="20px"
                sx={{ transform: 'scale(1, 1) !important' }}
              />
              <Skeleton
                variant="text"
                width="250px"
                height="20px"
                sx={{ transform: 'scale(1, 1) !important', marginRight: '50px' }}
              />
            </Stack>
          </Grid>
          <Grid
            item={true}
            xs={12}
            sm={6}
          >
            <Stack
              direction="column"
              spacing="16px"
              alignItems="center"
            >
              <Skeleton
                variant="circular"
                width="100px"
                height="100px"
                sx={{ transform: 'scale(1, 1) !important' }}
              />
              <Skeleton
                variant="text"
                width="300px"
                height="20px"
                sx={{ transform: 'scale(1, 1) !important' }}
              />
              <Skeleton
                variant="text"
                width="300px"
                height="20px"
                sx={{ transform: 'scale(1, 1) !important' }}
              />
              <Skeleton
                variant="text"
                width="250px"
                height="20px"
                sx={{ transform: 'scale(1, 1) !important', marginRight: '50px' }}
              />
            </Stack>
          </Grid>
          <Grid
            item={true}
            xs={12}
            sm={6}
          >
            <Stack
              direction="column"
              spacing="16px"
              alignItems="center"
            >
              <Skeleton
                variant="circular"
                width="100px"
                height="100px"
                sx={{ transform: 'scale(1, 1) !important' }}
              />
              <Skeleton
                variant="text"
                width="300px"
                height="20px"
                sx={{ transform: 'scale(1, 1) !important' }}
              />
              <Skeleton
                variant="text"
                width="300px"
                height="20px"
                sx={{ transform: 'scale(1, 1) !important' }}
              />
              <Skeleton
                variant="text"
                width="250px"
                height="20px"
                sx={{ transform: 'scale(1, 1) !important', marginRight: '50px' }}
              />
            </Stack>
          </Grid>
          <Grid
            item={true}
            xs={12}
            sm={6}
          >
            <Stack
              direction="column"
              spacing="16px"
              alignItems="center"
            >
              <Skeleton
                variant="circular"
                width="100px"
                height="100px"
                sx={{ transform: 'scale(1, 1) !important' }}
              />
              <Skeleton
                variant="text"
                width="300px"
                height="20px"
                sx={{ transform: 'scale(1, 1) !important' }}
              />
              <Skeleton
                variant="text"
                width="300px"
                height="20px"
                sx={{ transform: 'scale(1, 1) !important' }}
              />
              <Skeleton
                variant="text"
                width="250px"
                height="20px"
                sx={{ transform: 'scale(1, 1) !important', marginRight: '50px' }}
              />
            </Stack>
          </Grid>
        </Grid>
        <Box
          width="100%"
          marginTop="40px"
        >
          <Skeleton
            variant="text"
            width="100%"
            height="150px"
            sx={{ transform: 'scale(1, 1) !important' }}
          />
        </Box>
      </Container>
    </Box>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Homepage />
    </ThemeProvider>
  );
}

export default App;
